import { useQuery } from '@tanstack/react-query';
import { ProviderInfo, useAPI } from 'data/api';

const AUTHORIZATION_CODE = 'authorizationCode';
const AUTHORIZATION_CODE_PKCE = 'authorizationCodePKCE';

export type Providers = {
  [key: string]: ProviderInfo;
};

export const getIsScopeRequired = (provider: string, providers: Providers) => {
  const providerInfo: ProviderInfo = providers[provider];
  return providerInfo?.oauth2Opts?.explicitScopesRequired ?? false;
};

// getProxySupportEnabledProviders filters out providers that do not support OAuth2.
// If showProxyEnabledOnly is true, it also filters out providers that do not support proxy.
export const filterProviders = (providers: Providers, showProxyEnabledOnly: boolean) => {
  const proxyProviders: Providers = {};
  Object.keys(providers)
    .filter((provider) => {
      const proxyEnabled = showProxyEnabledOnly ? providers[provider].support.proxy : true;
      const isAuthCodeGrantType = providers[provider].oauth2Opts?.grantType === AUTHORIZATION_CODE
          || providers[provider].oauth2Opts?.grantType === AUTHORIZATION_CODE_PKCE;
      return proxyEnabled && isAuthCodeGrantType;
    })
    .forEach((provider) => {
      proxyProviders[provider] = providers[provider];
    });
  return proxyProviders;
};

export const useProviders = () => {
  const getAPI = useAPI();

  return useQuery({
    queryKey: ['listProviders'],
    queryFn: async () => {
      const api = await getAPI();
      return api.providerApi.listProviders();
    },
  });
};
