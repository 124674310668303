/* tslint:disable */
/* eslint-disable */
/**
 * Ampersand public API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApiKeyRequest
 */
export interface ApiKeyRequest {
    /**
     * A short name for the API key.
     * @type {string}
     * @memberof ApiKeyRequest
     */
    label: string;
    /**
     * The scopes for the API key.
     * @type {Array<string>}
     * @memberof ApiKeyRequest
     */
    scopes?: Array<string>;
}

/**
 * Check if a given object implements the ApiKeyRequest interface.
 */
export function instanceOfApiKeyRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "label" in value;

    return isInstance;
}

export function ApiKeyRequestFromJSON(json: any): ApiKeyRequest {
    return ApiKeyRequestFromJSONTyped(json, false);
}

export function ApiKeyRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiKeyRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'label': json['label'],
        'scopes': !exists(json, 'scopes') ? undefined : json['scopes'],
    };
}

export function ApiKeyRequestToJSON(value?: ApiKeyRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'label': value.label,
        'scopes': value.scopes,
    };
}

