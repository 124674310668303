import { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useGetMyInfoQuery } from 'src/hooks/useGetMyInfoQuery';
import { ProjectNotFoundPage } from 'src/views/ErrorPages/NoPermissionsPage';

import { useNavigate } from 'components/Nav';

// This magic token represents an empty project to be used in the URL,
// this token will cause a redirect to the select project page
const emptyProjectMagicToken = '_';

type ProjectProtectedPageProps = {
  children: React.ReactNode;
};

export const ProjectProtectedPage = ({ children }: ProjectProtectedPageProps) => {
  const { projectId } = useParams();
  const { data: myInfo } = useGetMyInfoQuery();

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (projectId && projectId === emptyProjectMagicToken) {
      // Redirect to select project page
      navigate('/select-project', { state: { from: location.pathname } });
    }
  }, [projectId, navigate, location.pathname]);

  useEffect(() => {
    if (myInfo?.projectRoles && projectId && !myInfo.projectRoles[projectId]) {
      // eslint-disable-next-line no-console
      console.error('User does not have access to this project');
    }
  }, [myInfo, projectId]);

  if (myInfo?.projectRoles && projectId && !myInfo.projectRoles[projectId]) {
    return <ProjectNotFoundPage />;
  }

  return children;
};
