import { useEffect } from 'react';
import { useGetMyInfoQuery } from 'src/hooks/useGetMyInfoQuery';

/**
 * During the organization creation, the user may accidentally create a project under a different organization
 * which should not be supported. This hook will check if the orgId is equal to the project orgId
 * and log an error if they are not equal so the user can contact support.
 * hook that will log an error if orgId is not equal to project orgId
 */
export const useIsOrgIdEqualProjectOrgId = () => {
  const { data: myInfo } = useGetMyInfoQuery();
  const orgId = myInfo?.orgRole?.org?.id;

  const projectIds = myInfo?.projectRoles ? Object.keys(myInfo?.projectRoles) : undefined;
  const projectId = projectIds?.[0];
  const projectOrgId = projectId ? myInfo?.projectRoles?.[projectId]?.project?.orgId : undefined;

  useEffect(() => {
    // if projectId is available, check if orgId is equal to projectOrgId
    if (projectId) {
      if (orgId !== projectOrgId) {
        // eslint-disable-next-line no-console
        console.error(
          `orgId ${orgId} is not equal to project ${projectId} orgId: ${projectOrgId}. Please contact Ampersand support,`,
        );
      }
    }
  }, [orgId, projectId, projectOrgId]);
};
