import { createRoutesFromElements, Navigate, Route } from 'react-router-dom';
import App from 'src/App';
import { ProtectedOnboardingLayout } from 'src/layout/OnboardingTemplate/ProtectedOnboardingLayout';
import { ProtectedAdminPage } from 'src/layout/ProtectedAdminPage/ProtectedAdminPage';
import { ProtectedPageNewUser } from 'src/layout/ProtectedPage/ProtectedPageNewUser';
import { ProtectedPageWithHomeSidebar } from 'src/layout/ProtectedPage/ProtectedPageWithHomeSidebar';
import { ProtectedPageWithProjectSidebar } from 'src/layout/ProtectedPage/ProtectedPageWithProjectSidebar';
import { NoMatchPage } from 'src/views/ErrorPages/NoMatchPage';
import { SelectProjectPage } from 'src/views/SelectProjectPage/SelectProjectPage';
import { SignInPage } from 'src/views/SignInPage';
import { SignUpPage } from 'src/views/SignUpPage';

// Lazy loaded pages - non-critical pages
import {
  CreateOrgPageLazy, CreateProjectPageLazy,
  MyProjectsPageLazy, OrgBillingPageLazy,
  OrgSettingsPageLazy, OrgUsersPageLazy,
} from './LazyPages/HomeLazyPages';
import {
  ApiKeysPageLazy,
  DestinationsPageLazy,
  GeneralSettingsPageLazy, InstallationsPageLazy, IntegrationPageLazy,
  OperationLogsPageLazy,
  OperationsPageLazy,
  ProviderAppsPageLazy,
} from './LazyPages/ProjectLazyPages';

/// ///////////////////////////////////////////

/**
 * Add routes for applications here.
 * In the future can lift and import routes from separate Route components
 * @returns
 */
export const appRoutes = createRoutesFromElements(
  <Route element={<App />}>
    <Route path="/">
      <Route index element={<Navigate to="/home" />} />
      <Route path="new-user" element={ProtectedPageNewUser} />
      <Route path="onboarding" element={ProtectedOnboardingLayout}>
        <Route path="create-org" index element={<CreateOrgPageLazy />} />
        <Route path="create-project" element={<CreateProjectPageLazy />} />
      </Route>
      <Route path="home" element={ProtectedPageWithHomeSidebar}>
        <Route index element={<MyProjectsPageLazy />} />
        <Route path="projects" element={<MyProjectsPageLazy />} />
        <Route path="org-settings" element={<ProtectedAdminPage><OrgSettingsPageLazy /></ProtectedAdminPage>} />
        <Route path="org-users" element={<ProtectedAdminPage><OrgUsersPageLazy /></ProtectedAdminPage>} />
        { import.meta.env.VITE_ALPHA_MODE === 'true' && (
        <Route
          path="org-billing"
          element={(
            <ProtectedAdminPage>
              <OrgBillingPageLazy />
            </ProtectedAdminPage>
              )}
        />
        )}
      </Route>
      <Route path="select-project" element={ProtectedOnboardingLayout}>
        <Route index element={<SelectProjectPage />} />
      </Route>
      <Route path="/projects" element={<Navigate to="/home/projects" />} />
      <Route path="projects/:projectId" element={ProtectedPageWithProjectSidebar}>
        <Route index element={<IntegrationPageLazy />} />
        <Route path="integrations/:integrationId" element={<InstallationsPageLazy />} />
        <Route path="integrations/:integrationId/installations/:installationId" element={<OperationsPageLazy />} />
        <Route
          path="integrations/:integrationId/installations/:installationId/operations/:operationId"
          element={<OperationLogsPageLazy />}
        />
        <Route path="settings" element={<GeneralSettingsPageLazy />} />
        <Route path="provider-apps/:providerId?" element={<ProviderAppsPageLazy />} />
        <Route path="destinations/:destinationId?" element={<DestinationsPageLazy />} />
        <Route path="api-keys" element={<ApiKeysPageLazy />} />
      </Route>
      {/* Custom Sign In */}
      <Route
        path="/sign-in"
        element={<SignInPage />}
      />
      <Route path="/sign-up" element={<SignUpPage />} />
      <Route path="*" element={NoMatchPage} />
    </Route>
  </Route>,
);
