import { FC } from 'react';
import { Navigate } from 'react-router-dom';
import { ORG_OWNER_ROLE, useGetMyInfoQuery } from 'src/hooks/useGetMyInfoQuery';

type ProtectedPageProps = {
  children: React.ReactNode;
};

/**
 * If user is not an admin (org owner), redirects to home page.
 *
 * @returns
 */
export const ProtectedAdminPage: FC<ProtectedPageProps> = ({ children }) => {
  const { data: builderInfo } = useGetMyInfoQuery();
  const isOrgOwner = builderInfo?.orgRole?.role === ORG_OWNER_ROLE;

  if (!builderInfo) return null;
  if (isOrgOwner) return children;

  return (<Navigate to="/" />);
};
